<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />
    <div class="wrap mt-5" role="document">
      <main class="main">
        <div class="page-checkin">
          <mdb-row class="justify-content-around">
            <mdb-col md="8">
              <mdb-alert v-if="error" color="danger" style="font-size: 1.8em;">{{error}}</mdb-alert>
            </mdb-col>
          </mdb-row>
          <div class="hazardous" v-if="!error">
            <div class="container">
              <div class="checkin-header">
                <h2 class="title title-sec">{{$t('webcheckinagreement.hazardous')}}</h2>
              </div>
              <div class="materials">
                <div class="material">
                  <a href="#material-info" class="material-info-icon js-material-info-icon" v-on:click="showliquid = true"><i class="fas fa-info"></i></a>

                  <div class="material-content">
                    <div class="material-icon">
                      <i class="fa fa-spray-can"></i>
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.liquid')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-flare.png" alt="Icons-flare">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.explosives')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-fire.png" alt="Icons-fire">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.flammables')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-bottle.png" alt="Icons-bottle">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.oxidizers')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-battery.png" alt="Icons-battery">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.corrosives')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-nuclear.png" alt="Icons-nuclear">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.radioactive')}}</p>
                  </div>
                </div>

                <div class="material">
                  <div class="material-content">
                    <div class="material-icon">
                      <img src="/public/images/Hasardous-Icons-danger.png" alt="Icons-danger">
                    </div>

                    <p class="text text-big">{{$t('webcheckinagreement.toxins')}}</p>
                  </div>
                </div>
              </div>
              <mdb-modal :show="showliquid" @close="showliquid = false" size="lg" class="text-center" danger style="z-index: 10000;">
                <mdb-modal-header style="font-size: 2em;">
                  {{$t('webcheckinagreement.liquid')}}
                </mdb-modal-header>
                <mdb-modal-body>
                  <div>
                    <img src="/public/images/OWG-Carry-on-bagage-FR4.jpg" alt="Carry on limit" v-if="$store.state.currentlang === 'fr'">
                    <img src="/public/images/OWG-Carry-on-bagage-EN4.jpg" alt="Carry on limit" v-else>
                  </div>
                </mdb-modal-body>
                <mdb-modal-footer>
                  <mdb-btn v-on:click="showliquid = false" color="elegant">{{$t('webcheckinagreement.close')}}</mdb-btn>
                </mdb-modal-footer>
              </mdb-modal>

                <div class="warning">
                  <div class="checkbox">
                    <div class="check">
                      <mdb-input
                        class="my-2"
                        type="checkbox"
                        id="agreementok"
                        v-model="cancontinue"
                      />
                    </div>
                  </div>
                  <p class="text">{{$t('webcheckinagreement.bottomtext')}}</p></div>

                <div class="checkin-buttons">
                  <button type="submit" class="button js-hazardous-next-button" v-if="cancontinue" v-on:click="gotonextstep">{{$t('webcheckinagreement.nextstep')}}</button>
                  <button type="submit" disabled class="button js-hazardous-next-button" v-else>{{$t('webcheckinagreement.nextstep')}}</button>
                </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <publicfooter />
  </div>
</template>

<script>
import {
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbAlert,
  mdbRow,
  mdbCol,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'checkin',
  data() {
    return {
      error: null,
      switchlangurl: '/',
      currentoken: '',
      cancontinue: false,
      showliquid: false,
    };
  },
  components: {
    publicheader,
    publicfooter,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbAlert,
    mdbRow,
    mdbCol,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    this.currentoken = this.$route.params.token;
    this.validatetoken();
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
  },
  methods: {
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = '/enregistrement';
      } else {
        this.switchlangurl = '/check-in';
      }
    },
    validatetoken() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/validate-token', { token: this.currentoken }).then((response) => {
        if (response.status !== 'OK') {
          self.error = response.msg;
        }
      });
    },
    gotonextstep() {
      if (this.$store.state.currentlang === 'fr') {
        this.$router.push({ name: 'PassagersInformations', params: { token: this.currentoken } });
      } else {
        this.$router.push({ name: 'PassengersInformations', params: { token: this.currentoken } });
      }
    },
  },
};
</script>

<style>
header{z-index: 0!important;}
.modal-header a.close{
  font-size: 2em;
}
</style>
