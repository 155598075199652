<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />
    <div style="min-height: 600px; text-align: center" v-if="issearching">
      <mdb-spinner size="lg" />
    </div>

    <mdb-container v-if="error" class="pt-5 pb-5">
      <mdb-row class="justify-content-around">
        <mdb-col md="8">
          <mdb-alert color="danger" style="font-size: 1.8em;">{{error}}</mdb-alert>
        </mdb-col>
      </mdb-row>
    </mdb-container>

    <mdb-container v-if="!issearching && !error" class="mt-5">
      <mdb-row>
        <mdb-col>
          <h1>{{$t('webcheckinpaxesselect.paxestocheckin')}}</h1>
        </mdb-col>
      </mdb-row>
      <mdb-container style="border: solid 1px #8b8b8b; border-radius: 15px" class="mb-5">
        <mdb-row class=" mt-5 mb-5">
          <mdb-col class="text-center">
            <h2 style="font-size: 3em;font-weight: bold;">{{$t('webcheckinpaxesselect.flight')}} {{flightinfos.flightnum}}</h2>
          </mdb-col>
        </mdb-row>
        <mdb-row class="justify-content-around">
          <mdb-col md="6">
            <h2>{{ flightinfos.departure.airportcity }} ({{ flightinfos.departure.airportcode }})</h2>
            <span style="font-size: 1.2em;">{{$t('webcheckinpaxesselect.planneddeparture')}} {{formatTime(flightinfos.departure.scheduleddatetime)}} ({{formatDate(flightinfos.departure.scheduleddatetime)}})</span>
          </mdb-col>
          <mdb-col md="6" class="text-md-right">
            <h2>{{ flightinfos.arrival.airportcity }} ({{ flightinfos.arrival.airportcode }})</h2>
            <span style="font-size: 1.2em;">{{$t('webcheckinpaxesselect.plannedarrival')}} {{formatTime(flightinfos.arrival.scheduleddatetime)}} ({{formatDate(flightinfos.arrival.scheduleddatetime)}})</span>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-icon icon="circle" size="3x" style="position: relative; left:20px; top:13px;" />
          <mdb-col class="text-center">
            <mdb-badge style="font-size: 2em;z-index: 1000; border-radius: 16px; background-color: #e91951!important;" class="chip waves-effect pr-5 pl-5 pt-2 mt-3">
              {{flightDuration()}}
            </mdb-badge>
            <span class="line-within"></span>
          </mdb-col>
          <mdb-icon icon="circle" size="3x" style="position: relative; right:20px; top:13px;" />
        </mdb-row>
        <mdb-row class="pb-5">
          <mdb-col class="pl-4" style="font-size: 1.7em; font-weight: bold;">
            {{formatTime(flightinfos.departure.realdatetime)}}
            <span style="font-size: 0.7em;" :class="getStatusColor()">
              ({{flightinfos.status.text}})
            </span>
          </mdb-col>
          <mdb-col class="text-right pr-4" style="font-size: 1.7em; font-weight: bold;">
            {{formatTime(flightinfos.arrival.realdatetime)}}
          </mdb-col>
        </mdb-row>
      </mdb-container>

      <mdb-container>
        <mdb-row>
          <mdb-col>
            <h2>{{$t('webcheckinpaxesselect.choosepassengers')}}</h2>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <mdb-tbl>
              <mdb-tbl-body>
                <tr v-for="(passenger,index) in flightinfos.passengers" v-bind:key="passenger.id">
                  <td style="width: 20px;">
                    <mdb-input
                      class="my-2"
                      type="checkbox"
                      :id="passenger.id"
                      v-model="passengers[index].selected"
                      v-on:change="handlechangestate"
                    />
                  </td>
                  <td class="align-bottom" style="font-size: 1.7em;">
                    {{ passenger.firstname }} {{ passenger.lastname }}
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-col>
        </mdb-row>
        <mdb-row class="pb-5">
          <mdb-col class="text-right">
            <mdb-btn color="elegant" v-if="cancontinue" v-on:click="saveandgotonextstep">{{$t('webcheckinpaxesselect.startcheckin')}}</mdb-btn>
            <mdb-btn color="elegant" v-else disabled="">{{$t('webcheckinpaxesselect.startcheckin')}}</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </mdb-container>
    <publicfooter />
  </div>
</template>

<script>
import {
  mdbSpinner,
  mdbAlert,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbBadge,
  mdbIcon,
  mdbBtn,
  mdbInput,
  mdbTbl,
  mdbTblBody,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'checkin',
  data() {
    return {
      error: null,
      switchlangurl: '/',
      currentoken: '',
      issearching: true,
      flightinfos: null,
      passengers: [],
      todelete: true,
      cancontinue: false,
    };
  },
  components: {
    publicheader,
    publicfooter,
    mdbSpinner,
    mdbAlert,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBadge,
    mdbIcon,
    mdbBtn,
    mdbInput,
    mdbTbl,
    mdbTblBody,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    this.currentoken = this.$route.params.token;
    this.loadFromToken();
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
  },
  methods: {
    handlechangestate(v) {
      this.$nextTick(() => {
        let isok = false;
        for (let i = 0; i < this.passengers.length; i += 1) {
          if (this.passengers[i].selected) {
            isok = true;
          }
        }
        this.cancontinue = isok;
      });
    },
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = '/enregistrement';
      } else {
        this.switchlangurl = '/check-in';
      }
    },
    formatTime(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      return thedate.toLocaleTimeString('en-CA', options);
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    flightDuration() {
      const dep = Moment(this.flightinfos.departure.realdatetime);
      const arr = Moment(this.flightinfos.arrival.realdatetime);
      const hours = Moment.duration(arr.diff(dep)).hours();
      const minutes = Moment.duration(arr.diff(dep)).minutes();

      return `${hours} hr ${minutes} min`;
    },
    loadFromToken() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/get-base-infos-from-token', { token: this.currentoken }).then((response) => {
        if (response.status === 'OK') {
          self.flightinfos = response.data;
          for (let i = 0; i < self.flightinfos.passengers.length; i += 1) {
            self.passengers[i] = {
              id: self.flightinfos.passengers[i].id,
              selected: false,
            };
          }
        } else {
          self.error = response.msg;
        }
        self.issearching = false;
      });
    },
    getStatusColor() {
      let classname = 'status-green';
      switch (this.flightinfos.status.text) {
        case 'On Time':
          classname = 'status-green';
          break;
        default:
          classname = 'status-red';
          break;
      }
      return classname;
    },
    saveandgotonextstep() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/save-passengers-to-checkin', { token: this.currentoken, passengers: this.passengers }).then((response) => {
        if (response.status === 'OK') {
          if (this.$store.state.currentlang === 'fr') {
            self.$router.push({ name: 'EnregistrementAccord', params: { token: self.currentoken } });
          } else {
            self.$router.push({ name: 'CheckinAgreement', params: { token: self.currentoken } });
          }
        } else {
          self.error = response.msg;
        }
      });
    },
  },
};
</script>
<style scope>
.line-within{
  position: absolute;
  height: 1px;
  width: calc(100% - 20px);
  top: 28px;
  left: 10px;
  background-color: rgba(0,0,0,.2);
}
.status-green{
  color: #00C851;
}
.status-red{
  color: #CC0000;
}
</style>
