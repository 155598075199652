<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />

    <div class="wrap" role="document">
      <main class="main">
        <div class="page-checkin">

          <div class="page-header small">
            <div class="page-header-image"></div>

            <div class="page-header-content">
              <div class="container">
                <h1 class="title title-small title-white title-shadow">{{$t('webcheckinroot.checkin')}}</h1>
              </div>
            </div>
          </div>
          <div class="checkin-login">

            <div class="login-too-early message-early" v-if="error">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-sm-10 col-md-8 col-lg-6">
                    <h2 class="title title-white">{{error_title}}</h2>
                    <p class="text text-white">{{error}}</p>
                    <a href="https://owg.com" class="button button-full button-white">{{$t('webcheckinroot.backtohomepage')}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="container" v-else>
              <div class="checkin-login-form">
                <div class="row justify-content-center">
                  <div class="col-md-11 col-lg-10">
                    <div class="wysiwyg">
                      <mdb-alert color="danger" class="form-col-33 text-center col-md-8 offset-md-2 mb-md-5" style="font-size: 1.3em;" v-if="little_error">{{little_error}}</mdb-alert>
                      <form class="gform">
                        <div class="gform_body">
                          <div class="gform_fields">
                            <div class="gfield form-col-33 form-col-33-first">
                              <label class="gfield_label" for="pnr">{{$t('webcheckinroot.bookingnumber')}}<span class="gfield_required">*</span></label>
                              <input type="text" id="pnr" name="pnr" required="" v-model="bookinginfos.bookingnumber">
                            </div>
                            <div class="gfield form-col-33 form-col-33-last" style="margin: 0 2%">
                              <label class="gfield_label" for="lastname">{{$t('webcheckinroot.lastname')}}<span class="gfield_required">*</span></label>
                              <input type="text" id="lastname" name="lastname" value="" required="" v-model="bookinginfos.lastname">
                            </div>
                            <mdb-spinner v-if="issearching" size="lg" style="margin-top:30px;" />
                            <div class="clear-float"></div>
                          </div>
                        </div>

                        <div class="form-button">
                          <button v-if="!issearching" type="button" class="button" id="btnReservation" v-on:click="searchForBooking()">{{$t('webcheckinroot.search')}}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="login-info">
                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <h2 class="title">{{$t('webcheckinroot.webcheckinwelcome')}}</h2>

                    <ul class="login-info-list">
                      <li>{{$t('webcheckinroot.maintxt1')}}</li>
                      <li>{{$t('webcheckinroot.maintxt2')}}</li>
                      <li>{{$t('webcheckinroot.maintxt3')}}</li>
                    </ul>

                    <p class="text text-small"><strong>{{$t('webcheckinroot.maintxt4')}}</strong></p>

                    <div class="login-details">
                      <div class="login-detail">
                        <div class="login-icon">
                          <img src="/public/images/Icon-check-white_23551926.svg" alt="Icon Check">
                        </div>
                        <div class="login-text">
                          <p class="text text-smaller" v-html="$t('webcheckinroot.feature1')"></p>
                        </div>
                      </div>

                      <div class="login-detail">
                        <div class="login-icon">
                          <img src="/public/images/Icon-seat-white_0e7ac4b5.svg" alt="Icon seat">
                        </div>
                        <div class="login-text">
                          <p class="text text-smaller" v-html="$t('webcheckinroot.feature2')"></p>
                        </div>
                      </div>

                      <div class="login-detail">
                        <div class="login-icon">
                          <img src="/public/images/Icon-baggage-white_5907da81.svg" alt="Icon baggage">
                        </div>
                        <div class="login-text">
                          <p class="text text-smaller" v-html="$t('webcheckinroot.feature3')"></p>
                        </div>
                      </div>

                      <div class="login-detail">
                        <div class="login-icon">
                          <img src="/public/images/Icon-ticket-white_42bb20a8.svg" alt="Icon ticket">
                        </div>
                        <div class="login-text">
                          <p class="text text-smaller" v-html="$t('webcheckinroot.feature4')"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-5">
                    <div class="login-image"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <publicfooter />
  </div>
</template>

<script>
import {
  mdbAlert,
  mdbSpinner,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'checkin',
  data() {
    return {
      error: null,
      error_title: null,
      little_error: null,
      switchlangurl: '/',
      istoosoon: false,
      bookinginfos: {
        bookingnumber: null,
        lastname: null,
      },
      issearching: false,
    };
  },
  components: {
    mdbAlert,
    mdbSpinner,
    publicheader,
    publicfooter,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
  },
  methods: {
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = '/enregistrement';
      } else {
        this.switchlangurl = '/check-in';
      }
    },
    searchForBooking() {
      this.issearching = true;
      const self = this;
      const api = new Apicall();
      self.little_error = null;
      self.error = null;
      api.call('POST', 'api/checkin/generate-token-for-bookingnumber', this.bookinginfos).then((response) => {
        self.issearching = false;
        if (response.status === 'OK') {
          let viewname = 'EnregistrementPassagers';
          if (self.lang === 'en') {
            viewname = 'CheckinPassengers';
          }
          self.$router.push({
            name: viewname,
            params: {
              token: response.data.token,
            },
          });
        }
        if (response.status === 'ERROR') {
          if (response.data.errtype === 'NOT_FOUND') {
            self.little_error = response.msg;
          } else {
            self.error = response.msg;
            self.error_title = response.data.errtitle;
          }
        }
      });
    },
  },
};
</script>
