<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />
    <mdb-container v-if="isloading">
      <mdb-spinner />
      <mdb-alert v-if="error" style="">{{error}}</mdb-alert>
    </mdb-container>
    <mdb-container v-else>
      <mdb-alert v-if="error" style="">{{error}}</mdb-alert>
      <mdb-row class="mt-3">
        <mdb-col>
          <h2>{{$t('webcheckinpaxinfos.passengers')}}</h2>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mt-2 mb-4">
        <mdb-col md="4">
          <mdb-list-group class="">
            <mdb-list-group-item tag="a" href="javascript:;" v-for="(pax,index) in passengersinfos" v-bind:key="index" :active="index===0?true:false" ><span v-on:click="showPaxForm(pax.id)" style="width: 100%;">{{pax.firstname}} {{pax.lastname}} <mdb-badge color="primary" pill v-if="pax.isprofilecomplete">{{$t('webcheckinpaxinfos.checkedin')}}</mdb-badge></span></mdb-list-group-item>
          </mdb-list-group>
        </mdb-col>
        <mdb-col md="8">
          <mdb-card v-for="(pax,index) in passengersinfos" v-bind:key="pax.id">
            <mdb-card-header color="white-text" class="" style="background-color: #173862;" v-if="pax.id === currentActivePax">{{pax.firstname}} {{pax.lastname}}</mdb-card-header>
            <mdb-card-body v-if="pax.id === currentActivePax">
              <mdb-row>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.birthdate')" v-model="passengersinfos[index].birthdate" disabled />
                </mdb-col>
                <mdb-col>
                  <mdb-select
                    color="primary"
                    :label="$t('webcheckinpaxinfos.gender')"
                    v-model="passengersinfos[index].gender"
                  />
                </mdb-col>
                <mdb-col>
                  <mdb-select
                    color="primary"
                    :label="$t('webcheckinpaxinfos.nationality')"
                    v-model="passengersinfos[index].nationality"
                    :search=true
                    :searchPlaceholder="$t('webcheckinpaxinfos.search')"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <h3>{{$t('webcheckinpaxinfos.checkedbaggages')}}</h3>
              <mdb-row>
                <mdb-col v-if="passengersinfos[index].nbbags > 1">
                  {{passengersinfos[index].nbbags}} {{$t('webcheckinpaxinfos.checkedbaggages')}}
                  <div v-if="passengersinfos[index].nbowoszbags">
                    <br />
                  dont {{passengersinfos[index].nbowoszbags}} {{$t('webcheckinpaxinfos.oversize')}}
                  </div>
                </mdb-col>
                <mdb-col v-else>
                  {{$t('webcheckinpaxinfos.checkedbaggagesnotice')}}
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <h3>{{$t('webcheckinpaxinfos.traveldocument')}}</h3>
              <mdb-row>
                <mdb-col>
                  <mdb-select
                    color="primary"
                    :label="$t('webcheckinpaxinfos.documenttype')"
                    v-model="passengersinfos[index].traveldocumenttype"
                    :search=false
                    :searchPlaceholder="$t('webcheckinpaxinfos.search')"
                  />
                </mdb-col>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.documentnumber')" v-model="passengersinfos[index].traveldocumentnumber" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-select
                    color="primary"
                    :label="$t('webcheckinpaxinfos.documentissuingcountry')"
                    v-model="passengersinfos[index].traveldocumentcountryofissue"
                    :search=true
                    :searchPlaceholder="$t('webcheckinpaxinfos.search')"
                  />
                </mdb-col>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.traveldocumentexpirationdate')" />
                  <mdb-date-picker-2
                    ref="startAtDatePicker"
                    :title="$t('webcheckinpaxinfos.traveldocumentexpirationdate')"
                    v-model="passengersinfos[index].traveldocumentexpirationdate" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <h3>{{$t('webcheckinpaxinfos.secureflightdetails')}}</h3>
              <mdb-row>
                <mdb-col>
                  {{$t('webcheckinpaxinfos.secureflightnotice')}}
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.redressnumber')" v-model="passengersinfos[index].redressnumber" />
                </mdb-col>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.redressnumber')" v-model="passengersinfos[index].knowntravelernumber" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <h3>{{$t('webcheckinpaxinfos.contactinformations')}}</h3>
              <mdb-row>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.email')" v-model="passengersinfos[index].email" />
                </mdb-col>
                <mdb-col>
                  <mdb-input :label="$t('webcheckinpaxinfos.cellphone')" v-model="passengersinfos[index].cellphone" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <div class="warning">
                    <div class="checkbox">
                      <div class="check">
                        <mdb-input
                          class="my-2"
                          type="checkbox"
                          id="agreementok"
                          v-model="passengersinfos[index].contactconsent"
                          :label="$t('webcheckinpaxinfos.consentcheckbox')"
                        />
                      </div>
                    </div>
                  </div>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <h3>{{$t('webcheckinpaxinfos.healthcheck')}}</h3>
              <mdb-row>
                <mdb-col>
                  <mdb-file-input ref="inputfilehndl" @getValue="getFileInputValueForQrCode" :width="300" btnColor="mdb-color" rounded :btn-title="$t('webcheckinpaxinfos.chooseqrcodeimage')" img src="https://mdbootstrap.com/img/Photos/Others/placeholder.webp" />
                </mdb-col>
                <mdb-col>
                  <div v-if="passengersinfos[index].qrcoderesult">
                    <mdb-alert color="success" v-if="passengersinfos[index].qrcoderesult.isvalid">
                      <h3>Status</h3>
                      <p>{{$t('webcheckinpaxinfos.vaccinepassportok')}}</p>
                    </mdb-alert>
                    <mdb-alert color="danger" v-else>
                      <h3>Status</h3>
                      <p>{{passengersinfos[index].qrcoderesult.msg}}</p>
                    </mdb-alert>
                  </div>
                  <div v-else>
                    <h3>{{$t('webcheckinpaxinfos.novaccinepassport')}}</h3>
                  </div>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col>
                  <hr />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="text-right">
                  <mdb-btn color="elegant" v-on:click="savePaxInfos(true)">{{$t('webcheckinpaxinfos.nextstep')}}</mdb-btn>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <publicfooter />
    <canvas ref="imageditor" style="display: none;"></canvas>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbListGroup,
  mdbListGroupItem,
  mdbBadge,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbFileInput,
  mdbBtn,
  mdbSpinner,
  mdbSelect,
  mdbDatePicker2,
} from 'mdbvue';
import QrScanner from 'qr-scanner';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'checkin',
  data() {
    return {
      error: null,
      switchlangurl: '/',
      currentoken: '',
      cancontinue: false,
      isloading: true,
      passengersinfos: [],
      currentActivePax: '',
      nationalitylist: [],
      doctypes: [],
      qrcodes: [],
      canvas: null,
      filereader: null,
      myimg: null,
      ctx: null,
    };
  },
  components: {
    publicheader,
    publicfooter,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbListGroup,
    mdbListGroupItem,
    mdbBadge,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbFileInput,
    mdbBtn,
    mdbSpinner,
    mdbSelect,
    mdbDatePicker2,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    this.currentoken = this.$route.params.token;
    this.retrievepaxinfos();
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
  },
  methods: {
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = '/enregistrement';
      } else {
        this.switchlangurl = '/check-in';
      }
    },
    async retrievepaxinfos() {
      await this.getNationalityList();
      await this.getDocTypes();
      const self = this;
      const api = new Apicall();
      const genderoptions = [
        { text: 'Homme', value: 'M', selected: false },
        { text: 'Femme', value: 'F', selected: false },
      ];
      api.call('POST', 'api/checkin/get-paxes-infos', { token: this.currentoken }).then((response) => {
        self.passengersinfos = response.data.passengers;
        // Building gender dropdown
        self.passengersinfos.forEach((pax, index) => {
          const tmpgenderoptions = genderoptions;
          tmpgenderoptions.forEach((gender, index2) => {
            if (gender.value === pax.gender) {
              tmpgenderoptions[index2].selected = true;
            }
          });
          // building nationality dropdown
          const tmpnationalityarr = [];
          self.nationalitylist.forEach((nation) => {
            tmpnationalityarr.push({
              text: `${nation.nationality} (${nation.name})`,
              value: nation.alpha3,
              selected: pax.nationality === nation.alpha3,
            });
          });
          // building country of emmission dropdown
          const tmpemissioncountryarr = [];
          self.nationalitylist.forEach((nation) => {
            tmpemissioncountryarr.push({
              text: nation.name,
              value: nation.alpha3,
              selected: pax.traveldocumentcountryofissue === nation.alpha3,
            });
          });
          // building doctype dropdown
          const tmpdoctypearr = [];
          self.doctypes.forEach((doc) => {
            tmpdoctypearr.push({
              text: doc.docname,
              value: doc.id,
              selected: pax.traveldocumenttype === doc.id,
            });
          });
          self.passengersinfos[index].traveldocumenttype = tmpdoctypearr;
          self.passengersinfos[index].traveldocumentcountryofissue = tmpemissioncountryarr;
          self.passengersinfos[index].nationality = tmpnationalityarr;
          self.passengersinfos[index].gender = tmpgenderoptions;
        });
        self.currentActivePax = self.passengersinfos[0].id;
        self.isloading = false;
      });
    },
    async getNationalityList() {
      const self = this;
      const api = new Apicall();
      const response = await api.call('GET', 'api/checkin/available-nationalities');
      self.nationalitylist = response.data;
      return true;
    },
    async getDocTypes() {
      const self = this;
      const api = new Apicall();
      const response = await api.call('GET', 'api/checkin/available-doctypes');
      self.doctypes = response.data;
      return true;
    },
    getFileInputValueForQrCode(v) {
      let currentpaxindex = 0;
      this.passengersinfos.forEach((pax, index) => {
        if (pax.id === this.currentActivePax) {
          currentpaxindex = index;
        }
      });
      this.passengersinfos[currentpaxindex].qrcoderesult = null;
      this.passengersinfos[currentpaxindex].qrcodecontent = [];
      const tmp = v[0];
      this.myimg = tmp;
      this.filereader = new FileReader();
      this.filereader.onloadend = () => {
        this.img = new Image();
        this.img.onload = () => {
          this.$refs.imageditor.width = this.img.width;
          this.$refs.imageditor.height = this.img.height;
          this.canvas = this.$refs.imageditor;
          this.ctx = this.canvas.getContext('2d');
          this.ctx.drawImage(this.img, 0, 0);
          this.scanTheCanvas();
        };
        this.img.src = this.filereader.result;
      };
      this.filereader.readAsDataURL(this.myimg);
    },
    scanTheCanvas() {
      let currentpaxindex = 0;
      this.passengersinfos.forEach((pax, index) => {
        if (pax.id === this.currentActivePax) {
          currentpaxindex = index;
        }
      });
      const self = this;
      QrScanner.scanImage(this.$refs.imageditor, { returnDetailedScanResult: true }).then((res) => {
        const points = self.findRect(res.cornerPoints);
        this.canvas = this.$refs.imageditor;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillRect(points.x, points.y, points.width, points.height);
        self.passengersinfos[currentpaxindex].qrcodecontent.push(res.data);
        if (self.passengersinfos[currentpaxindex].qrcodecontent.length < 2) {
          self.scanTheCanvas();
        } else {
          self.validateQrCodeAndPushToServer();
        }
      }).catch((res) => {
        self.validateQrCodeAndPushToServer();
      });
    },
    findRect(points) {
      let minX = null;
      let minY = null;
      let maxX = null;
      let maxY = null;
      points.forEach((point) => {
        if (minX === null || point.x < minX) {
          minX = point.x;
        }
        if (maxX === null || point.x > maxX) {
          maxX = point.x;
        }
        if (minY === null || point.y < minY) {
          minY = point.y;
        }
        if (maxY === null || point.y > maxY) {
          maxY = point.y;
        }
      });
      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
      };
    },
    validateQrCodeAndPushToServer() {
      let currentpaxindex = 0;
      this.passengersinfos.forEach((pax, index) => {
        if (pax.id === this.currentActivePax) {
          currentpaxindex = index;
        }
      });

      const payload = {
        token: this.currentoken,
        firstname: this.passengersinfos[currentpaxindex].firstname,
        lastname: this.passengersinfos[currentpaxindex].lastname,
        dob: this.passengersinfos[currentpaxindex].birthdate,
        qrcontent: this.passengersinfos[currentpaxindex].qrcodecontent,
      };
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/validate-qr-code', payload).then((response) => {
        self.passengersinfos[currentpaxindex].qrcoderesult = response.data;
      });
    },
    showPaxForm(id) {
      this.currentActivePax = id;
      this.savePaxInfos(false);
    },
    savePaxInfos(gotonextstep) {
      const paxinfos = JSON.parse(JSON.stringify(this.passengersinfos)); // Petit hack pour ne plus être référencé à la variable d'origine
      const self = this;
      const api = new Apicall();
      for (let i = 0; i < paxinfos.length; i += 1) {
        paxinfos[i].gender = this.setSelectedValue(paxinfos[i].gender);
        paxinfos[i].nationality = this.setSelectedValue(paxinfos[i].nationality);
        paxinfos[i].traveldocumentcountryofissue = this.setSelectedValue(paxinfos[i].traveldocumentcountryofissue);
        paxinfos[i].traveldocumenttype = this.setSelectedValue(paxinfos[i].traveldocumenttype);
      }
      api.call('POST', 'api/checkin/temporary-save-paxes-infos', { token: this.currentoken, paxes: paxinfos }).then(() => {
        if (gotonextstep) {
          if (self.$store.state.currentlang === 'fr') {
            self.$router.push({ name: 'PassagersBagagesEnregistres', params: { token: self.currentoken } });
          } else {
            self.$router.push({ name: 'PassengersCheckedBaggage', params: { token: self.currentoken } });
          }
        }
      });
    },
    setSelectedValue(dropdownarr) {
      let datatoreturn = '';
      for (let i = 0; i < dropdownarr.length; i += 1) {
        if (dropdownarr[i].selected) {
          datatoreturn = dropdownarr[i].value;
        }
      }
      return datatoreturn;
    },
  },
};
</script>

<style>
header{z-index: 0!important;}
.modal-header a.close{
  font-size: 2em;
}
.fs14{
  font-size: 1.4em;
}
</style>
