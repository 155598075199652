<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />

    <div class="wrap" role="document">
      <main class="main">
        <div class="page-checkin">

          <div class="page-header small">
            <div class="page-header-image"></div>

            <div class="page-header-content">
              <div class="container">
                <h1 class="title title-small title-white title-shadow">{{title}}</h1>
              </div>
            </div>
          </div>
          <div class="checkin-login">

            <div class="login-too-early message-early" v-if="error">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-sm-10 col-md-8 col-lg-6">
                    <h2 class="title title-white">{{error_title}}</h2>
                    <p class="text text-white">{{error}}</p>
                    <a href="https://owg.com" class="button button-full button-white">{{$t('webcheckinroot.backtohomepage')}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="container" v-else>
              <div class="alert-content">
                <div class="row justify-content-center">
                  <div class="col-md-11 col-lg-10">
                    <div class="alertwysiwyg">
                      <div v-html="alertcontent"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>

    <publicfooter />
  </div>
</template>

<script>
import Apicall from '@/libs/Apicall';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'alert',
  data() {
    return {
      error: null,
      error_title: null,
      alertcontent: null,
      title: null,
      switchlangurl: null,
    };
  },
  components: {
    publicheader,
    publicfooter,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
    this.loadAlert();
  },
  methods: {
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = `/alerte/${this.$route.params.token}`;
      } else {
        this.switchlangurl = `/alert/${this.$route.params.token}`;
      }
    },
    loadAlert() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/owgpublic/get-alert', { ident: this.$route.params.token }).then((response) => {
        if (response.status === 'OK') {
          self.title = response.data.alertinfos.title;
          self.alertcontent = response.data.alertinfos.content;
        } else {
          self.error = self.$t('publicalerts.notfound');
          self.error_title = self.$t('publicalerts.error');
        }
      });
    },
  },
};
</script>
<style>
  .alert-content{
    padding-top:30px;
    font-size:1.4rem;
  }
  .alertwysiwyg p{
    margin-bottom:0px;
  }
  .alertwysiwyg{
    padding-bottom: 75px;
  }
</style>
