<template>
  <div style="font-size: 10px!important;">
    <nav class="nav-sec js-nav-sec">
      <ul id="menu-secondary_navigation_fr" class="menu">
        <li class="menu-item menu-a-propos">
          <a :href="$t('publictemplate.aboutus_url')" target="_blank">{{$t('publictemplate.aboutus')}}</a>
        </li>
        <li class="menu-item menu-nos-aeronefs">
          <a :href="$t('publictemplate.ourfleet_url')" target="_blank">{{$t('publictemplate.ourfleet')}}</a>
        </li>
        <li class="menu-item menu-faq">
          <a :href="$t('publictemplate.faq_url')" target="_blank">{{$t('publictemplate.faq')}}</a>
        </li>
        <li class="menu-item menu-nouvelles">
          <a :href="$t('publictemplate.news_url')" target="_blank">{{$t('publictemplate.news')}}</a>
        </li>
        <li class="wpml-ls-slot-18 wpml-ls-item wpml-ls-item-en wpml-ls-menu-item wpml-ls-first-item wpml-ls-last-item menu-item menu-en">
          <a v-if="$i18n.locale == 'fr'" title="EN" :href=switchlangurl><span class="wpml-ls-native" lang="en">EN</span></a>
          <a v-else title="FR" :href=switchlangurl><span class="wpml-ls-native" lang="en">FR</span></a>
        </li>
      </ul>

      <ul class="nav-search-wrap" style="height: 35px;">
        <li>
          &nbsp;
        </li>
      </ul>
    </nav>
    <header class="header js-header">
      <a class="brand js-brand" href="https://owg.com/">
        <img src="https://owg.com/wp-content/themes/owg/dist/images/OWG-logo_8c7cd45a.svg" alt="OWG logo">
      </a>

      <nav class="nav-lang" style="right: 10px;">
        <ul id="menu-lang_navigation_en" class="menu">
          <li class="wpml-ls-slot-15 wpml-ls-item wpml-ls-item-en wpml-ls-menu-item wpml-ls-first-item wpml-ls-last-item menu-item menu-en">
            <a v-if="$i18n.locale == 'fr'" title="EN" :href=switchlangurl><span class="wpml-ls-native" lang="en">EN</span></a>
            <a v-else title="FR" :href=switchlangurl><span class="wpml-ls-native" lang="en">FR</span></a>
          </li>
        </ul>
      </nav>

    </header>
  </div>
</template>

<script>
export default {
  name: 'publicheader',
  data() {
    return {
      error: null,
    };
  },
  props: ['switchlangurl'],
  mounted() {
    // todo!
  },
  methods: {
    dummy() {
      // nada
    },
  },
};
</script>

<style>
  @import './public.css';
</style>
<style>
  @import './fa.all.min.css';
</style>
