<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="footer-logo">
          <img src="https://owg.com/wp-content/themes/owg/dist/images/OWG-logo_8c7cd45a.svg" alt="OWG logo">
        </div>

        <nav class="nav-footer js-nav-footer">
          <ul id="menu-footer_navigation_en" class="menu"><li class="menu-item menu-item-has-children menu-planifier-votre-voyage"><a href="#">{{$t('publictemplate.footer_plan_your_trip')}}</a>
            <ul class="sub-menu">
              <li class="menu-item menu-informations-generales"><a :href="$t('publictemplate.footer_general_informations_url')">{{$t('publictemplate.footer_general_informations')}}</a></li>
              <li class="menu-item menu-bagages"><a :href="$t('publictemplate.footer_baggages_url')">{{$t('publictemplate.footer_baggages')}}</a></li>
              <li class="menu-item menu-covid-19"><a :href="$t('publictemplate.footer_covid_19_url')">{{$t('publictemplate.footer_covid_19')}}</a></li>
              <li class="menu-item menu-familles-et-enfants"><a :href="$t('publictemplate.footer_families_children_url')">{{$t('publictemplate.footer_families_children')}}</a></li>
              <li class="menu-item menu-services-speciaux"><a :href="$t('publictemplate.footer_specials_services_url')">{{$t('publictemplate.footer_specials_services')}}</a></li>
              <li class="menu-item menu-aeroports"><a :href="$t('publictemplate.footer_airports_url')">{{$t('publictemplate.footer_airports')}}</a></li>
              <li class="menu-item menu-services-en-vol"><a :href="$t('publictemplate.footer_in_flight_url')">{{$t('publictemplate.footer_in_flight')}}</a></li>
              <li class="menu-item menu-droits-des-voyageurs"><a :href="$t('publictemplate.footer_travelers_rights_url')">{{$t('publictemplate.footer_travelers_rights')}}</a></li>
              <li class="menu-item menu-faq"><a :href="$t('publictemplate.footer_faq_url')">{{$t('publictemplate.footer_faq')}}</a></li>
            </ul>
          </li>
            <li class="menu-item menu-item-has-children menu-lentreprise"><a href="#">{{$t('publictemplate.footer_our_campany')}}</a>
              <ul class="sub-menu">
                <li class="menu-item menu-a-propos"><a :href="$t('publictemplate.footer_about_us_url')">{{$t('publictemplate.footer_about_us')}}</a></li>
                <li class="menu-item menu-nos-aeronefs"><a :href="$t('publictemplate.footer_our_fleet_url')">{{$t('publictemplate.footer_our_fleet')}}</a></li>
                <li class="menu-item menu-fonds-decran"><a :href="$t('publictemplate.footer_wallpapers_url')">{{$t('publictemplate.footer_wallpapers')}}</a></li>
              </ul>
            </li>
            <li class="menu-item menu-item-has-children menu-service-a-la-clientele"><a href="#">{{$t('publictemplate.footer_customer_support')}}</a>
              <ul class="sub-menu">
                <li class="bold menu-item menu-nous-appeler"><a href="#">{{$t('publictemplate.footer_call_us')}}</a></li>
                <li class="menu-item menu-1-866-love-owg"><a href="tel:18665683694">1.866.LOVE.OWG</a></li>
              </ul>
            </li>
            <li class="menu-item menu-item-has-children menu-nolisement"><a href="#">{{$t('publictemplate.footer_charter_services')}}</a>
              <ul class="sub-menu">
                <li class="menu-item menu-nolisement-pour-groupes-et-evenements"><a :href="$t('publictemplate.footer_charter_flights_for_groups_url')">{{$t('publictemplate.footer_charter_flights_for_groups')}}</a></li>
              </ul>
            </li>
          </ul>
        </nav>

        <div class="copyrights">
          <h6 class="copy">{{$t('publictemplate.footer_permit')}}</h6>

          <nav class="nav-conditions js-nav-conditions">
            <ul id="menu-conditions_navigation_fr" class="menu"><li class="menu-item menu-contact"><a :href="$t('publictemplate.footer_contact_url')">{{$t('publictemplate.footer_contact')}}</a></li>
              <li class="menu-item menu-tarif"><a target="_blank" rel="noopener" :href="$t('publictemplate.footer_tariff_url')">{{$t('publictemplate.footer_tariff')}}</a></li>
              <li class="menu-item menu-conditions-dutilisation"><a :href="$t('publictemplate.footer_terms_of_use_url')">{{$t('publictemplate.footer_terms_of_use')}}</a></li>
              <li class="menu-item menu-politique-de-confidentialite"><a :href="$t('publictemplate.footer_privacy_policy_url')">{{$t('publictemplate.footer_privacy_policy')}}</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'publicfooter',
  data() {
    return {
      error: null,
    };
  },
  mounted() {
    // todo!
  },
  methods: {
    dummy() {
      // nada
    },
  },
};
</script>
