<template>
  <div>
    <publicheader :switchlangurl=switchlangurl />
    <mdb-container>
      <mdb-container v-if="isloading || error">
        <mdb-spinner v-if="isloading" />
        <mdb-alert v-if="error" style="">{{error}}</mdb-alert>
      </mdb-container>
      <mdb-container v-else>
        <mdb-row class="mt-3">
          <mdb-col>
            <h2>{{$t('webcheckinpaxinfos.passengers')}}</h2>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mt-2 mb-4">
          <mdb-col md="4">
            <mdb-list-group class="">
              <mdb-list-group-item tag="a" href="javascript:;" v-for="(pax,index) in paxesinfos" v-bind:key="index" :active="index===0?true:false" ><span v-on:click="togglepax(pax.id)" style="width: 100%;">{{pax.firstname}} {{pax.lastname}} <mdb-badge color="primary" pill class="float-right" v-if="pax.currentseat">{{pax.currentseat}}</mdb-badge></span></mdb-list-group-item>
            </mdb-list-group>
          </mdb-col>
          <mdb-col md="8" class="card-background">
            <mdb-card>
              <mdb-card-body>
                <mdb-row class="">
                  <mdb-col>
                    <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;" color="primary-color-dark" text="white"> &nbsp; </mdb-chip> <span style="position:relative; top:-25px" class="font-weight-bolder">Disponible</span>
                    <br />
                    <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;" color="pink darken-1" text="white"> &nbsp; </mdb-chip> <span style="position:relative; top:-25px" class="font-weight-bolder">Premium</span>
                  </mdb-col>
                  <mdb-col>
                    <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;" color="purple darken-3" text="white"> &nbsp; </mdb-chip> <span style="position:relative; top:-25px" class="font-weight-bolder">Sortie de secours</span><br />
                    <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;"> &nbsp; </mdb-chip> <span style="position:relative; top:-25px" class="font-weight-bolder">Non disponible</span>
                  </mdb-col>
                </mdb-row>
                <hr />
                <mdb-tbl borderless sm>
                  <mdb-tbl-head>
                    <tr class="text-center">
                      <th>A</th>
                      <th>B</th>
                      <th>C</th>
                      <th> &nbsp; </th>
                      <th>D</th>
                      <th>E</th>
                      <th>F</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body v-if="!seatmustrefresh">
                    <tr v-for="rownum in getNbRows()" v-bind:key="rownum" class="text-center">
                      <td v-for="letter in ['A','B','C']" v-bind:key="`${rownum}${letter}`" >
                        <div v-if="isSeatAvailable(rownum, letter)" v-on:click="selectSeat(rownum, letter)">
                          <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;" :color="getSeatColor(rownum, letter)" text="white"> &nbsp; </mdb-chip>
                        </div>
                        <div v-else>
                          <mdb-chip pill size="md" class="pl-4 pr-4" style="border-radius: 15px!important;cursor: unset;" :color="getSeatColor(rownum, letter)" text="white" v-if="ispaxInCurrentBookingIsSeated(rownum, letter)"><strong>{{getInitialsOfPaxOnThisSeat(rownum, letter)}}</strong></mdb-chip>
                          <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important; cursor: unset;" text="white" v-else tag="span"> &nbsp; </mdb-chip>
                        </div>
                      </td>

                      <td class="bold text-center"> <h4>{{rownum}}</h4> </td>

                      <td v-for="letter in ['D','E','F']" v-bind:key="`${rownum}${letter}`">
                        <div v-if="isSeatAvailable(rownum, letter)" v-on:click="selectSeat(rownum, letter)">
                          <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important;" :color="getSeatColor(rownum, letter)" text="white" v-on:click="selectSeat(rownum, letter)"> &nbsp; </mdb-chip>
                        </div>
                        <div v-else>
                          <mdb-chip pill size="md" class="pl-4 pr-4" style="border-radius: 15px!important; cursor: unset;" :color="getSeatColor(rownum, letter)" text="white" v-if="ispaxInCurrentBookingIsSeated(rownum, letter)"><strong>{{getInitialsOfPaxOnThisSeat(rownum, letter)}}</strong></mdb-chip>
                          <mdb-chip pill size="md" class="pl-4 pr-5" style="border-radius: 15px!important; cursor : unset;" text="white" v-else> &nbsp; </mdb-chip>
                        </div>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
        <mdb-row class="pb-5">
          <mdb-col class="text-right">
            <mdb-btn color="elegant" v-on:click="gotonextstep()">{{$t('webcheckinpaxinfos.nextstep')}}</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </mdb-container>
    <publicfooter />
    <mdb-modal :show="seatlocking" :close="false" size="md" class="text-center" :elegant="true" centered>
      <mdb-modal-header center :close="false">
        <p class="heading">Sauvegarde de votre siège en cours...</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-spinner size="large" />
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbListGroup,
  mdbListGroupItem,
  mdbBadge,
  mdbCard,
  mdbCardBody,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbChip,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import publicheader from '../Templates/Header.vue';
import publicfooter from '../Templates/Footer.vue';

export default {
  name: 'checkin',
  data() {
    return {
      error: null,
      switchlangurl: '/',
      currentoken: '',
      paxesinfos: [],
      seatsmaps: {},
      seatmustrefresh: false,
      isloading: true,
      seatsNbRows: 0,
      currentpax: 0,
      seatlocking: false,
    };
  },
  components: {
    publicheader,
    publicfooter,
    mdbContainer,
    mdbAlert,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbListGroup,
    mdbListGroupItem,
    mdbBadge,
    mdbCard,
    mdbCardBody,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbChip,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
  },
  props: ['lang'],
  mounted() {
    this.setLangFromProp(this.lang);
    this.currentoken = this.$route.params.token;
    this.validatetoken();
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', `https://go.owg.com/public/go.js?${new Date().getTime()}`);
    document.body.appendChild(scriptEle);
    scriptEle.addEventListener('load', () => {
      window.owg.setLang(this.lang);
      window.owg.setOrigin(window.location);
      window.owg.dontExecuteCheckinOverride();
      window.owg.setTopElementSelector('#app > div > div:nth-child(1) > nav');
      window.owg.init();
    });
  },
  methods: {
    setLangFromProp(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
      this.setSwitchLangUrl(lang);
    },
    setSwitchLangUrl(lang) {
      if (lang === 'en') {
        this.switchlangurl = '/enregistrement';
      } else {
        this.switchlangurl = '/check-in';
      }
    },
    validatetoken() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/validate-token', { token: this.currentoken }).then((response) => {
        if (response.status !== 'OK') {
          self.error = response.msg;
        } else {
          self.retrieveseatsmap();
        }
      });
    },
    retrieveseatsmap() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/retrieve-seats-map-for-booking', { token: this.currentoken }).then((response) => {
        if (response.status === 'OK') {
          self.paxesinfos = response.data.paxdata;
          self.seatsmaps = response.data.seatsmap;
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    getNbRows() {
      let max = 0;
      for (let i = 0; i < this.seatsmaps.length; i += 1) {
        const num = parseInt(this.seatsmaps[i].SeatNo.slice(0, -1), 10);
        if (num > max) {
          max = num;
        }
      }
      return max;
    },
    isSeatAvailable(rownum, letter) {
      const currentpax = this.paxesinfos[this.currentpax];
      let isavailable = false;
      const theseat = `${rownum}${letter}`;
      for (let i = 0; i < currentpax.availableseats.length; i += 1) {
        if (currentpax.availableseats[i]) {
          if (currentpax.availableseats[i].SeatNo === theseat && this.paxesinfos[this.currentpax].currentseat !== theseat) {
            isavailable = true;
          }
        }
      }
      for (let i = 0; i < this.paxesinfos.length; i += 1) {
        if (this.paxesinfos[i].currentseat === theseat) {
          isavailable = false;
        }
      }
      return isavailable;
    },
    getInitialsOfPaxOnThisSeat(rownum, letter) {
      let initials = '';
      for (let i = 0; i < this.paxesinfos.length; i += 1) {
        if (this.paxesinfos[i].currentseat === `${rownum}${letter}`) {
          initials = `${this.paxesinfos[i].firstname.charAt(0)}${this.paxesinfos[i].lastname.charAt(0)}`;
        }
      }
      return initials;
    },
    ispaxInCurrentBookingIsSeated(rownum, letter) {
      return this.paxesinfos[this.currentpax].currentseat === `${rownum}${letter}`;
    },
    selectSeat(rownum, letter) {
      this.paxesinfos[this.currentpax].currentseat = `${rownum}${letter}`;
      this.seatlocking = true;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/checkin/lock-seat-for-passenger', { token: this.currentoken, pax: this.paxesinfos[this.currentpax] }).then((response) => {
        self.seatmustrefresh = true;
        self.$nextTick().then(() => {
          self.seatmustrefresh = false;
          self.seatlocking = false;
        });
      });
    },
    gotonextstep() {
      console.log('goto page de validation des informations');
    },
    getSeatColor(rownum, letter) {
      const theseat = `${rownum}${letter}`;
      let type = 'S';
      for (let i = 0; i < this.seatsmaps.length; i += 1) {
        if (this.seatsmaps[i].SeatNo === theseat) {
          type = this.seatsmaps[i].SeatTypeCode;
        }
      }
      switch (type) {
        case 'P':
          return 'pink darken-1';
        case 'E':
          return 'purple darken-3';
        default:
          return 'primary-color-dark';
      }
    },
  },
};
</script>
